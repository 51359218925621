import type { ReactNode, MouseEvent as rme } from "react";

import "./button.scss";

export type BaseButtonClickHandler = (
  event: rme<HTMLButtonElement, MouseEvent>,
) => void;
export interface BaseButtonProps {
  className?: string;
  onClick?: BaseButtonClickHandler;
  children?: ReactNode;
}
/** Simple button component */
const BaseButton = ({
  children,
  ...BaseButtonProps
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button {...BaseButtonProps}>{children}</button>
);
export interface BaseFileButtonProps extends BaseButtonProps {
  type?: string;
}
export const BaseFileViewVideoButton = ({
  children,
  className,
  type,
}: BaseFileButtonProps) => (
  <div className="scan-item scanned backup-item ">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
    </svg>
    <button className={className}>{children}</button>
  </div>
);

export const BaseFileViewAudioButton = ({
  children,
  className,
  type,
}: BaseFileButtonProps) => (
  <div className="scan-item scanned backup-item">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"
      />
    </svg>
    <button className={className}>{children}</button>
  </div>
);
export const BaseFileViewPhotoButton = ({
  children,
  className,
  type,
}: BaseFileButtonProps) => (
  <div className="scan-item scanned backup-item">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
        clipRule="evenodd"
      />
    </svg>
    <button className={className}>{children}</button>
  </div>
);

export default BaseButton;
