import { useCallback, useEffect, useReducer, useState } from "react";

import {
  KeyActions,
  KeyContextProvider,
  type KeyStreamEvent,
  useKeyContext,
} from "src/hooks/context/scan";
import TextInput from "src/ui/base/text-input";

interface ConfirmOrderScanProps {
  itemList: string[];
  onConfirm: (confirm: boolean) => void;
  err: { state: boolean; message: string };
}

const useScanBox = () => {
  const initialState = {
    scan: "",
    ready: false,
  };
  const reducer = useReducer(
    (state: typeof initialState, action: KeyStreamEvent) => {
      switch (action.type) {
        case KeyActions.key:
          return { ...state, scan: state.scan + action.key };
        case KeyActions.delete:
          return { ...state, scan: state.scan.slice(0, -1) };
        case KeyActions.enter:
          return { ...state, ready: true };
        default:
          return state;
      }
    },
    initialState,
  );

  return reducer;
};

function ScanBox({ itemList, onConfirm, err }: ConfirmOrderScanProps) {
  const subscribe = useKeyContext();
  const [{ scan, ready }, dispatch] = useScanBox();

  useEffect(() => {
    if (!ready) {
      return;
    }

    if (itemList.includes(scan)) {
      onConfirm(true);
    } else {
      onConfirm(false);
    }
  }, [ready, scan, itemList, onConfirm]);

  useEffect(() => {
    const unsubscribe = subscribe((event) => {
      dispatch(event);
    });

    return () => {
      unsubscribe();
    };
  }, [subscribe, onConfirm]);
  return <TextInput val={scan} error={err.state} errorMsg={err.message} />;
}

function ConfirmOrderScan(props: ConfirmOrderScanProps) {
  return (
    <KeyContextProvider>
      <section className="ship-order__print-label-wrapper">
        <h1 className="ship-order__print-label-header">Confirm Order Scan</h1>
        <ScanBox {...props} />
      </section>
    </KeyContextProvider>
  );
}

export default ConfirmOrderScan;
