import { useMemo, type ReactNode } from "react";
import { AnalogItem, selectBrand, selectOrderId } from "../state/slice/data";
import classNames from "classnames";
import { RiFileDamageLine } from "react-icons/ri";
import { FaGoogleDrive, FaRegQuestionCircle } from "react-icons/fa";
import { CiCloudOff, CiCloudOn } from "react-icons/ci";
import { AiOutlineLoading } from "react-icons/ai";
import {
  ApiItemCloudStatus,
  useListItemCloudStatusQuery,
} from "../state/services/api";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
interface ItemContainerProps {
  department: string;
  children: ReactNode;
}

export function ItemContainer({ department, children }: ItemContainerProps) {
  return (
    // <ErrorBoundary>
    <section className="item-container">
      <div className="item-container__header">Scan all {department} items</div>
      {children}
    </section>
    // </ErrorBoundary>
  );
}

interface ItemListProps {
  items: AnalogItem[];
}

export function ItemList({ items }: ItemListProps) {
  const brand = useSelector(selectBrand);
  const orderId = useSelector(selectOrderId);
  const skip = !brand || !orderId;

  const { data, isLoading, isSuccess, isError, isUninitialized } =
    useListItemCloudStatusQuery(
      {
        orderId,
        brand,
        barcodes: items.map((item) => item.item_barcode),
      },
      {
        skip,
      },
    );
  return (
    <div className="item-container__list">
      {items.map((item) => {
        const queryData = isSuccess
          ? data.find((d) => d?.itemId === item.item_barcode)
          : undefined;
        return (
          <Item
            key={item.item_barcode}
            item={item}
            queryData={{
              isSuccess,
              isError,
              isUninitialized,
              isLoading,
              data: queryData,
            }}
          />
        );
      })}
    </div>
  );
}

interface ItemCloudIconProps {
  service: ApiItemCloudStatus["service"];
  fileReady: boolean;
}

export const ItemCloudIcon = (props: ItemCloudIconProps) => {
  switch (props.service) {
    case "google":
      if (props.fileReady) {
        return <FaGoogleDrive />;
      }
      return <FaRegQuestionCircle />;
    case "cloud":
      if (props.fileReady) {
        return <CiCloudOn strokeWidth={1} />;
      }
      return <CiCloudOff strokeWidth={1} />;
    default:
      return null;
  }
};

interface ItemProps {
  item: AnalogItem;
  queryData: {
    isSuccess: boolean;
    isError: boolean;
    isUninitialized: boolean;
    isLoading: boolean;
    data: ApiItemCloudStatus | undefined;
  };
}

export function Item({ item, queryData }: ItemProps) {
  /** Damage types that shouldn't have files */
  const itemNoConvertDamaged = useMemo(
    () =>
      [
        "unfixable",
        "unsupported",
        "blank",
        "graphic",
        "packaging",
        "moldy",
      ].includes(item?.damage_type ?? ""),
    [item],
  );

  const { data, isLoading, isSuccess, isError, isUninitialized } = queryData;
  const fileCount = data?.fileCount ?? 0;

  const warn =
    isSuccess &&
    fileCount > 0 &&
    !itemNoConvertDamaged &&
    data?.fileReady === false;

  const itemClass = classNames("item-container__item", {
    "item-container__item--scanned": item.scanned,
    "item-container__item--loading":
      item.scanned && (isLoading || isUninitialized),
    "item-container__item--warn": item.scanned && warn,
    "item-container__item--error":
      item.scanned && isSuccess && !item.cloud && !itemNoConvertDamaged,
    "item-container__item--disabled": item.scanned && itemNoConvertDamaged,
  });

  /**
   * flag all damaged items
   * (include quality/repair issues that will have files)
   */
  const hasDamage =
    item.damage_type !== undefined &&
    item.damage_type !== null &&
    item.damage_type !== "none";

  const cloudIcon =
    isLoading && item.scanned ? (
      <AiOutlineLoading className="spinner" />
    ) : isError || isUninitialized ? null : item.scanned &&
      isSuccess &&
      fileCount > 0 ? (
      <ItemCloudIcon
        service={data?.service ?? "cloud"}
        fileReady={data?.fileReady ?? false}
      />
    ) : null;

  let fileCountDisplay = null;
  if (
    !isUninitialized &&
    !isError &&
    isSuccess &&
    data &&
    fileCount > 0 &&
    item.scanned &&
    !itemNoConvertDamaged
  ) {
    fileCountDisplay = <span className="count">{fileCount}</span>;
  }
  return (
    <div className={itemClass}>
      <span>{item.item_barcode}</span>
      <span>{cloudIcon}</span>
      <span>{hasDamage && <RiFileDamageLine />}</span>
      {fileCountDisplay}
    </div>
  );
}
