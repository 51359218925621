import { useDispatch, useSelector } from "react-redux";

import { SafetyCheckDepartment } from "./department";
import OrderHeader from "@ui/groups/order-header";
import { selectPage } from "./state/slice/page";
import { SafetyCheckScanOrder } from "./scan-order";
import { useLogin } from "@/hooks/context/login";
import { memo, useCallback, useEffect } from "react";
import { setToken } from "./state/slice/auth";
import { KeyContextProvider } from "@/hooks/context/scan";
import {
  resetDepartment,
  selectDepartment,
  selectOrderHeader,
} from "./state/slice/data";
import { SafetyCheckScanItems } from "./scan-items";
import { SafetyCheckReview } from "./review";
import BaseButton from "@/ui/base/button";

const MemoHeader = memo(OrderHeader);

function PageSelector() {
  const page = useSelector(selectPage);

  switch (page) {
    case "home":
      return <SafetyCheckScanOrder />;
    case "scan":
      return <SafetyCheckScanItems />;
    case "review":
      return <SafetyCheckReview />;
    case "complete":
      return <SafetyCheckReview />;
  }
}

export function SafetyCheckMain() {
  const [{ usertoken }] = useLogin();
  const dept = useSelector(selectDepartment);
  const headerData = useSelector(selectOrderHeader);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!usertoken) {
      return;
    }

    dispatch(setToken(usertoken));
  }, []);

  /** Should store to localstorage on init. */
  if (!dept) {
    return <SafetyCheckDepartment />;
  }

  const handleResetDept = () => dispatch(resetDepartment());

  return (
    <>
      <section className="safetycheck__head">
        <MemoHeader {...headerData} />
      </section>
      <section className="safetycheck__dept">
        <BaseButton onClick={handleResetDept}>{dept} ⟳</BaseButton>
      </section>
      <section className="safetycheck__progress-bar">
        {/* progress bar here, later */}
      </section>
      <section className="safetycheck__content">
        <KeyContextProvider>
          <PageSelector />
        </KeyContextProvider>
      </section>
    </>
  );
}
