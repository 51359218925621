import {
  addUpgradeClickAction,
  addUpgradeQuantity,
  isUpgradeType,
  removeUpgradeClick,
  removeUpgradeQuantity,
  setBaseQuantityAction,
} from "@/hooks/reducers/actions/receiving/upgrades";
import BaseButton from "@/ui/base/button";
import TextInput from "@/ui/base/text-input";
import UpgradeIndicator from "@ui/components/upgrade-indicator";
import { type Dispatch, useEffect, useMemo } from "react";

import "./card.scss";

interface UpgradesCardProps {
  /** Title marker */
  textContent: string;
  /** Total number of Upgrade/Outputs of type */
  quantity: number;
  /** Notes if upgrades were scanned */
  upgrade: boolean;
  /** Assigned barcode value for input display */
  val: string;
  /** Short code for output (upgrades keys: 'dd', 'td, 'dv) */
  upgradeType: "dd" | "td" | "dv";
  /** dispatch function for reducer */
  dispatch: Dispatch<any>;
  /** Array of upgrade outputs */
  outputs: { output_type: string; quantity: number }[];
  /** Original order quantity */
  orderQuantity: number;
}

const UpgradeButton = (props: UpgradesCardProps) => {
  const {
    dispatch,
    upgrade,
    upgradeType,
    textContent,
    quantity,
    orderQuantity,
  } = props;
  const shouldDisplayAddBtn = textContent !== "Digital Download";

  if (!isUpgradeType(upgradeType)) {
    throw new Error(`Invalid upgrade type: ${upgradeType}`);
  }

  const displayQuantity = orderQuantity + Number(quantity);

  if (!upgrade && !shouldDisplayAddBtn) {
    return <div />;
  }

  if (!upgrade && shouldDisplayAddBtn) {
    return (
      <BaseButton
        className="upgrades-card__add-btn"
        onClick={() => {
          dispatch(addUpgradeClickAction(upgradeType));
        }}
      >
        Add
      </BaseButton>
    );
  }

  return (
    <div className="upgrades-card__edit-wrapper">
      <BaseButton
        className="subtract-btn"
        disabled={displayQuantity === 1 || displayQuantity === orderQuantity}
        onClick={() => dispatch(removeUpgradeQuantity(upgradeType))}
      >
        -
      </BaseButton>
      <div className="upgrades-card__text-input-wrapper">
        <BaseButton
          className="upgrades-card__text-input-btn"
          onClick={() => {
            dispatch(removeUpgradeClick(upgradeType));
          }}
        >
          X
        </BaseButton>
        <TextInput
          val={`${upgradeType.toUpperCase()} - ${displayQuantity}`}
          className="upgrades-card__text-input"
        />
      </div>
      <BaseButton
        className="add-btn"
        onClick={() => dispatch(addUpgradeQuantity(upgradeType))}
      >
        +
      </BaseButton>
    </div>
  );
};

export const UpgradesCard = (props: UpgradesCardProps) => {
  const { textContent, quantity, upgrade, upgradeType, orderQuantity } = props;

  console.log(props);
  if (!isUpgradeType(upgradeType)) {
    throw new Error(`Invalid upgrade type: ${upgradeType}`);
  }

  let displayQuantity = orderQuantity + Number(quantity);

  return (
    <>
      <UpgradeIndicator
        textContent={textContent}
        quantity={displayQuantity}
        upgrade={upgrade}
      />
      <UpgradeButton {...props} />
    </>
  );
};
